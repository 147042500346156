import { Outlet } from 'react-router-dom';

import './index.css';
import { useResetRecoilState } from 'recoil';
import { reservationFilterStore } from '@/recoil/store/reservationFilter';
import { useEffect } from 'react';

const AppLayout = () => {

    const resetReservationFilter = useResetRecoilState(reservationFilterStore);

    // 마이페이지가 아닌 다른 페이지 이동 시 -> 예약 관련 필터 제거
    useEffect(() => {
        resetReservationFilter()
    }, [])

    return (
        <div className="AppRoot">
            <Outlet/>
        </div>
    );

};

export default AppLayout;

import { useEffect, useState } from 'react';

const useMediaQuery = (query: string) => {
    const media = window.matchMedia(query);
    const [matches, setMatches] = useState<boolean>(media.matches);

    useEffect(() => {
        const listener = () => setMatches(media.matches);
        media.addEventListener('change', listener);

        // 초기 상태 설정
        setMatches(media.matches);

        return () => media.removeEventListener('change', listener);
    }, [query]);

    return matches;
};

export default useMediaQuery;

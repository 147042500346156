import { atom } from 'recoil';

import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export interface reservationFilterInterface {
  DATE_TYPE: string;
  FROM_DATE: string;
  TO_DATE: string;
  APPLY: number;
  APPROVE: number;
  CONFIRM: number;
  COMPLETE: number;
  CANCEL: number;
}


export const reservationFilterStore = atom<reservationFilterInterface>({
  key: 'reservationFilter',
  default: {
    DATE_TYPE : 'all',
    FROM_DATE : '',
    TO_DATE : '',
    APPLY : 0,
    APPROVE : 0,
    CONFIRM : 0,
    COMPLETE : 0,
    CANCEL: 0
  },
  effects_UNSTABLE: [persistAtom]
});


import { useState } from 'react';

const useLightbox = (images: string[]) => {
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    const openLightbox = (index: number) => {
        setPhotoIndex(index);
        setIsOpen(true);
    };

    const closeLightbox = () => {
        setIsOpen(false);
    };

    const moveNext = () => {
        setPhotoIndex((photoIndex + 1) % images.length);
    };

    const movePrev = () => {
        setPhotoIndex((photoIndex + images.length - 1) % images.length);
    };

    return {
        isOpen,
        photoIndex,
        openLightbox,
        closeLightbox,
        moveNext,
        movePrev
    };
};

export default useLightbox;

import { lazy } from 'react';

import AuthLayout from '@/layout/authenticationLayout';
import Loadable from '@/components/Loadable';




const MypageReservationApply = Loadable(lazy(() => import('@/pages/app/mypage/reservation/myReservationApply')));
const MypageReservationApprove= Loadable(lazy(() => import('@/pages/app/mypage/reservation/myReservationApprove')));
const MypageReservationConfirm = Loadable(lazy(() => import('@/pages/app/mypage/reservation/myReservationConfirm')));
const MypageReservationComplete = Loadable(lazy(() => import('@/pages/app/mypage/reservation/myReservationComplete')));
const MypageReservationCancel = Loadable(lazy(() => import('@/pages/app/mypage/reservation/myReservationCancel')));
const MypageUserRegister = Loadable(lazy(() => import('@/pages/app/reservation/userRegister')));

const MyCreditCharge = Loadable(lazy(() => import('@/pages/app/mypage/creditCoupon/myCreditCharge')));
const MyCreditPayment = Loadable(lazy(() => import('@/pages/app/mypage/creditCoupon/myCreditPayment')));
const MyCreditUsage = Loadable(lazy(() => import('@/pages/app/mypage/creditCoupon/myCreditUsage')));
const MyCoupon = Loadable(lazy(() => import('@/pages/app/mypage/creditCoupon/myCoupon')));


const MypageReviewAdd = Loadable(lazy(() => import('@/pages/app/mypage/review/reviewAdd')));
const MypageReviewList = Loadable(lazy(() => import('@/pages/app/mypage/review/reviewList')));
const MypageReviewDetail = Loadable(lazy(() => import('@/pages/app/mypage/review/reviewDetail')));



const MypageUserInfo = Loadable(lazy(() => import('@/pages/app/mypage/information/userInfo')));
const MypageCompanyChange = Loadable(lazy(() => import('@/pages/app/mypage/information/companyChange')));
const MypageCompanyTerminate = Loadable(lazy(() => import('@/pages/app/mypage/information/companyTerminate')));






const AuthenticationRoutes = {
    path: '/',
    element: <AuthLayout />,
    children: [
        /* 예약 내역 */
        { path: 'mypage/reservation/apply', element: <MypageReservationApply /> },
        { path: 'mypage/reservation/approve', element: <MypageReservationApprove/> },
        { path: 'mypage/reservation/confirm', element: <MypageReservationConfirm /> },
        { path: 'mypage/reservation/complete', element: <MypageReservationComplete /> },
        { path: 'mypage/reservation/cancel', element: <MypageReservationCancel/> },
        { path: 'mypage/reservation/userRegister/*', element: <MypageUserRegister/> },
        /* 나의 크레딧*/
        { path: 'mypage/credit/charge', element: <MyCreditCharge/> },
        { path: 'mypage/credit/payment', element: <MyCreditPayment/> },
        { path: 'mypage/credit/usage', element: <MyCreditUsage/> },
        { path: 'mypage/coupon', element: <MyCoupon/> },
        /* 나의 리뷰 */
        { path: 'mypage/review/list', element: <MypageReviewList/> },
        { path: 'mypage/review/reviewAdd/*', element: <MypageReviewAdd/> },
        { path: 'mypage/review/reviewDetail/*', element: <MypageReviewDetail/> },
        /* 나의 회원 정보 */
        { path: 'mypage/user/info', element: <MypageUserInfo/> },
        { path: 'mypage/user/companyChange', element: <MypageCompanyChange/> },
        { path: 'mypage/user/companyTerminate', element: <MypageCompanyTerminate/> }
    ]
};

export default AuthenticationRoutes;












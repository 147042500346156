import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@/hooks/index';

const commonScrollY = 100;

const useGradient = () => {
    const [hideGradient, setHideGradient] = useState<boolean>(false);
    const location = useLocation();
    const isMobile = useMediaQuery('(max-width: 1000px)');
    const isMain = location.pathname === '/home';
    const isSpaceDetail = location.pathname.includes('/space');

    const showGradientBackground = useCallback(() => {
        const { scrollY } = window;

        if (isMobile) {
            setHideGradient(false);
            return;
        }

        if (scrollY > commonScrollY) {
            setHideGradient(true);
        } else {
            setHideGradient(false);
        }
    }, [setHideGradient, isMobile]);

    useEffect(() => {
        if (isMain || isSpaceDetail) {
            window.addEventListener('scroll', showGradientBackground);
        }

        return () => {
            window.removeEventListener('scroll', showGradientBackground);
        };
    }, [isMain, isSpaceDetail, showGradientBackground, location.pathname]);

    return hideGradient;
};

export default useGradient;

import { lazy } from 'react';
import AppLayout from '@/layout/appLayout';
import Loadable from '@/components/Loadable';

const Home = Loadable(lazy(() => import('@/pages/app/common/home')));
const Service = Loadable(lazy(() => import('@/pages/app/common/service')));
const CompanyGuide = Loadable(lazy(() => import('@/pages/app/common/companyGuide')));
const BranchList = Loadable(lazy(() => import('@/pages/app/branchProduct/branchList')));
const BranchDetail = Loadable(lazy(() => import('@/pages/app/branchProduct/branchDetail')));

const Login = Loadable(lazy(() => import('@/pages/app/common/login')));
const Register = Loadable(lazy(() => import('@/pages/app/common/register')));
const FindAccount = Loadable(lazy(() => import('@/pages/app/common/findAccount')));
const CompanyInquiry = Loadable(lazy(() => import('@/pages/app/common/companyInquiry')));
const CompanyNews = Loadable(lazy(() => import('@/pages/app/common/companyNews')));
const Faq = Loadable(lazy(() => import('@/pages/app/common/faq')));
const MyShoppingCart = Loadable(lazy(() => import('@/pages/app/common/shoppingCart')));

const ProductList = Loadable(lazy(() => import('@/pages/app/branchProduct/productList')));
const ProductDetail = Loadable(lazy(() => import('@/pages/app/branchProduct/productDetail')));
const ReviewDetail = Loadable(lazy(() => import('@/pages/app/branchProduct/reviewDetail')));

const ReservationApply = Loadable(lazy(() => import('@/pages/app/reservation/reservationApply')));
const ReservationComplete = Loadable(lazy(() => import('@/pages/app/reservation/reservationComplete')));
const ReservationPayment = Loadable(lazy(() => import('@/pages/app/reservation/reservationPayment')));
const ReservationDetail = Loadable(lazy(() => import('@/pages/app/reservation/reservationDetail')));


const UseTerm = Loadable(lazy(() => import('@/pages/app/terms/useTerm')));
const PrivacyTerm = Loadable(lazy(() => import('@/pages/app/terms/privacyTerm')));
const ThirdTerm = Loadable(lazy(() => import('@/pages/app/terms/thirdTerm')));
const RefundTerm = Loadable(lazy(() => import('@/pages/app/terms/refundTerm')));




const AppRoutes = {
    path: '/',
    element: <AppLayout />,
    children: [
        /* url 바로 접근시 */
        { path: '/', element: <Home /> },
        /* 공통 - 홈, 서비스 소개, 기업고객 안내 등 */
        { path: 'home', element: <Home /> },
        { path: 'service', element: <Service /> },
        { path: 'companyGuide', element: <CompanyGuide /> },
        /* 로그인, 회원가입, 계정찾기 */
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: 'findAccount', element: <FindAccount /> },
        { path: 'companyInquiry', element: <CompanyInquiry /> },
        { path: 'companyNews', element: <CompanyNews /> },
        { path: 'faq', element: <Faq /> },
        /* 장바구니 */
        { path: '/shoppingCart', element: <MyShoppingCart /> },

        /* 상품 상세 페이지 */
        { path: 'productDetail', element: <ProductDetail /> },

        /* 지점 상품 페이지 */
        { path: 'branch/list', element: <BranchList /> },
        { path: 'branch/detail/*', element: <BranchDetail /> },
        { path: 'product/list/*', element: <ProductList /> },
        { path: 'product/detail/*', element: <ProductDetail /> },
        { path: 'review/detail/*', element: <ReviewDetail /> },

        /* 예약 관련 페이지 */

        { path: 'reservation/apply/*', element: <ReservationApply /> },
        { path: 'reservation/complete/*', element: <ReservationComplete /> },
        { path: 'reservation/payment/*', element: <ReservationPayment /> },
        { path: 'reservation/detail/*', element: <ReservationDetail /> },

        /* 약관 관련 페이지 */
        { path: 'useTerm', element: <UseTerm /> },
        { path: 'privacyTerm', element: <PrivacyTerm /> },
        { path: 'thirdTerm', element: <ThirdTerm /> },
        { path: 'refundTerm', element: <RefundTerm /> },


        /* 404 페이지나 페이지가 없는 경우 - 홈으로 리다이렉트 */
        { path: '*', element: <Home /> }
    ]
};

export default AppRoutes;

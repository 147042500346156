import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <HelmetProvider>
        <RecoilRoot>
            <BrowserRouter basename="/">
                <App />
            </BrowserRouter>
        </RecoilRoot>
    </HelmetProvider>
);

import { useRef } from 'react';

/*
 *   Ex) const = useMoveScroll(1); 1을 넣으면 1개의 useRef가 생성됨
 *
 *   Usage)
 *   <div ref={elements[0]} />
 *   <button onClick={() => handleMoveToElement(0)} />
 */

const useMoveScroll = (count: number) => {
    const elements = Array.from({ length: count }).map(() =>
        useRef<HTMLDivElement>(null)
    );

    const handleMoveToElement = (index: number) => {
        elements[index].current?.scrollIntoView({
            behavior: 'smooth',
            block : 'nearest'
        });
    };

    return { elements, handleMoveToElement };
};

export default useMoveScroll;

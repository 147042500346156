import React, { useEffect, useState } from 'react';
import './App.css';
//
import Routes from '@/routes/index';
import { useMediaQuery } from '@/hooks/index';
import { AnimatePresence } from "framer-motion";
import { getCookie } from '@/utils/cookie';

function App() {

    const isMobile = useMediaQuery('(max-width: 600px)');



    useEffect(() => {

        const moveFrame = () => {

            const fcFrame = document.getElementById('fc_frame');
            // const launcherFrame = document.getElementById('launcher-frame');

            if (fcFrame) {
                if(isMobile){
                    fcFrame.style.bottom = '10px';
                    fcFrame.style.right = '10px';
                    fcFrame.style.zIndex = '5';
                }else{
                    fcFrame.style.zIndex = '5';
                }
            }

            // if (launcherFrame) {
            //
            //     if(isMobile){
            //         launcherFrame.style.bottom = '45px';
            //         launcherFrame.style.right = '5px';
            //         launcherFrame.style.zIndex = '5';
            //     }else{
            //         launcherFrame.style.bottom = '80px';
            //         launcherFrame.style.right = '35px';
            //         launcherFrame.style.zIndex = '5';
            //     }
            // }
        };


        const timer = setTimeout(() => {
            moveFrame();
        }, 2900);

        return () => clearTimeout(timer); // cleanup
    }, []);



    return (

        <div className="App">
            <AnimatePresence>
                <Routes />
            </AnimatePresence>

        </div>
    );
}

export default App;

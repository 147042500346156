import { Suspense } from 'react';

import Loader from './Loader';

const Loadable = (Component: any) => {
    const WrappedComponent = (props: any) => (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );

    WrappedComponent.displayName = `Loadable(${Component.displayName || Component.name || 'Component'})`;

    return WrappedComponent;
};

export default Loadable;

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import './index.css';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { authStore } from '@/recoil/store/auth';

const AuthenticationLayout = () => {

    // 페이지 이동 함수
    const navigate = useNavigate();
    const location = useLocation();

    const loginData : any=  useRecoilValue(authStore);

    useEffect(() => {

        if(loginData.EMAIL === ''){
            alert('잘못된 접근입니다')
            navigate('/home')
        }
    }, [])


    return (
        <div className="AppRoot">
            <Outlet/>
        </div>
    );
};

export default AuthenticationLayout;

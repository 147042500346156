import { useState } from 'react';

export type stepType = {
    id: number;
    label: string;
};

/*
 * @useStepper hook
 *
 * @example
 * const steps = [
 *  { id: 1, label: 'step1' },
 *  { id: 2, label: 'step2' },
 *  { id: 3, label: 'step3' },
 * ];
 *
 * const { currentStep, nextStep, previousStep, goToStep } = useStepper(steps);
 */
const useStepper = (steps: stepType[]) => {
    const [currentStep, setCurrentStep] = useState(1);

    const nextStep = () => {
        if (currentStep < steps.length) {
            setCurrentStep((prev) => prev + 1);
        }
    };

    const previousStep = () => {
        if (currentStep > 1) {
            setCurrentStep((prev) => prev - 1);
        }
    };

    const goToStep = (step: number) => {
        if (step >= 1 && step <= steps.length) {
            setCurrentStep(step);
        }
    };

    return {
        currentStep,
        nextStep,
        previousStep,
        goToStep
    };
};

export default useStepper;

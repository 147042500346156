import { useRoutes } from 'react-router-dom';
import AppRoutes from './appRoutes';
import AuthenticationRoutes from './authenticationRoutes';


const Routes = () => {
    return useRoutes([AppRoutes, AuthenticationRoutes]);
};

export default Routes;

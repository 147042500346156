import { atom } from 'recoil';

import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export interface authInterface {
  EMAIL: string;
  TYPE: string;
  LEVEL: string;
  NAME: string;
  COMPANY_NM: string;
  TOKEN: string;
}


export const authStore = atom<authInterface>({
  key: 'account',
  default: {
    EMAIL: '',
    TYPE: '',
    LEVEL: '',
    NAME: '',
    COMPANY_NM : '',
    TOKEN : '' },
  effects_UNSTABLE: [persistAtom]
});


export interface userDetailInterface {
  EMAIL: string;
  NAME: string;
  PHONE: string;
  PHONE_CERT: string;
  LEVEL: string;
  ORG_NO: number;
  ORG_NM: string;
  ORG_ADMIN_NM: string;
  ORG_ADMIN_TEL: string;
  GRP_NO: null | number;
  GRP_NM: null | string;
  EMP_NO: null | number;
  ORG_IN_DATE: string;
  ADM_NM: null | string;
  ADM_IN_DATE: null | string;
  DEL_YN: string;
  DELETE_DATE: null | string;
  DELETE_REASON: null | string;
  MEM_IN_DATE: string;
  MODIFY_DATE: string;
  ORG_REQ_YN: string;
}


export const userStore = atom<userDetailInterface>({
  key: 'userDetail',
  default: {
    EMAIL: '',
    NAME: '',
    PHONE: '',
    PHONE_CERT: '',
    LEVEL: '',
    ORG_NO: 0,
    ORG_NM: '',
    ORG_ADMIN_NM: '',
    ORG_ADMIN_TEL: '',
    GRP_NO: null,
    GRP_NM: null,
    EMP_NO: null,
    ORG_IN_DATE: '',
    ADM_NM: null,
    ADM_IN_DATE: null,
    DEL_YN: '',
    DELETE_DATE: null,
    DELETE_REASON: null,
    MEM_IN_DATE: '',
    MODIFY_DATE: '',
    ORG_REQ_YN: '',
  },
  effects_UNSTABLE: [persistAtom]
});

export interface orgDetailInterface {
  ORG_NO: number;
  OWNER_NM: string;
  ORG_BRN: string;
  ORG_BRN_NO: string;
  ORG_NM: string;
  ADMIN_EMAIL: string;
  ADMIN_NM: string;
  ADMIN_TEL: string;
  USE_DATE: null | string;
  REG_DATE: string;
  MOD_DATE: null | string;
}

export const orgStore = atom<orgDetailInterface>({
  key: 'orgDetail',
  default: {
    ORG_NO: 0,
    OWNER_NM: '',
    ORG_BRN: '',
    ORG_BRN_NO: '',
    ORG_NM: '',
    ADMIN_EMAIL: '',
    ADMIN_NM: '',
    ADMIN_TEL: '',
    USE_DATE: null,
    REG_DATE: '',
    MOD_DATE: null
  },
  effects_UNSTABLE: [persistAtom]
});


